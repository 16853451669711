<template>
  <el-card class="tabCard" style="margin-top: 20px;">
    <!-- <tab-nav :tabs="true" @change="handleCurrentClickTab">
      <tab-nav-items
        v-show="showTabItem.includes('intro')"
        :active="active == 'intro'"
        href="#intro"
        :title="$t('Events.97c@introduce')"
      />
      <tab-nav-items
        
        :active="active == 'terms'"
        href="#terms"
        title="Terms & Conditions"
      />
      <tab-nav-items
        v-show="showTabItem.includes('syllabus')"
        @click.native="courseTab"
        :active="active == 'syllabus'"
        href="#syllabus"
        title="Syllabus"
      />
      <tab-nav-items
        v-show="showTabItem.includes('coc')"
        :active="active == 'COC'"
        href="#COC"
        :title="$t('Events.83c@staff')"
      />
      <tab-nav-items
        @click.native="eventProgram"
        v-show="showTabItem.includes('allProgram') && package_type != 1"
        :active="active == 'allProgram'"
        href="#allProgram"
        title="All Program"
      />
      <tab-nav-items
        v-show="showTabItem.includes('media')"
        :active="active == 'Media'"
        href="#Media"
        :title="$t('Events.f87@media')"
      />
      <tab-nav-items
        v-show="showTabItem.includes('comment')"
        :active="active == 'Comment'"
        href="#Comment"
        :title="$t('Events.2ea@comment')"
      />
      <tab-nav-items
        v-show="showTabItem.includes('questions')"
        :active="active == 'Questions'"
        href="#Questions"
        :title="$t('Events.b9a@questions')"
      />
      <tab-nav-items
        v-show="showTabItem.includes('attendees')"
        :active="active == 'Attendees'"
        href="#Attendees"
        :title="$t('Events.08f@attendess')"
      />
      <tab-nav-items
        v-show="showTabItem.includes('certificate')"
        :active="active == 'Certificate'"
        href="#Certificate"
        :title="$t('Events.c8e@certificate')"
      />
      <tab-nav-items
        v-show="showTabItem.includes('result')"
        :active="active == 'Results'"
        href="#Results"
        title="Results"
      />
      <tab-nav-items
        v-show="showTabItem.includes('JCIprogramList') && has_program"
        :active="active == 'JCIprogramList'"
        @click.native="getProgram()"
        href="#JCIprogramList"
        title="JCI Signature Program"
      />
    </tab-nav>
    <tab-content>
      <tab-content-item :active="active == 'intro'" id="intro">
        <Intro />
      </tab-content-item>
      <tab-content-item :active="active == 'terms'" id="terms">
        <Terms />
      </tab-content-item>
      <tab-content-item :active="active == 'syllabus'" id="syllabus">
        <Syllabus :sylla="syllabusList" />
      </tab-content-item>
      <tab-content-item :active="active == 'allProgram'" id="allProgram">
        <AllProgram :allProgramList="allProgramList" />
      </tab-content-item>
      <tab-content-item :active="active == 'COC'" id="COC">
        <COC :type="type" />
      </tab-content-item>
      <tab-content-item :active="active == 'Media'" id="Media">
        <Media @changeCurrentMediaType="getCurrentMediaType" />
      </tab-content-item>
      <tab-content-item :active="active == 'Comment'" id="Comment">
        <Comment :type="type" />
      </tab-content-item>
      <tab-content-item :active="active == 'Questions'" id="Questions">
        <Questions :type="type" />
      </tab-content-item>
      <tab-content-item :active="active == 'Attendees'" id="Attendees">
        <Attendees :type="type" />
      </tab-content-item>
      <tab-content-item :active="active == 'Certificate'" id="Certificate">
        <Certificate />
      </tab-content-item>
      <tab-content-item :active="active == 'Results'" id="Results">
        <Result />
      </tab-content-item>
      <tab-content-item
        :active="active == 'JCIprogramList'"
        id="JCIprogramList"
      >
        <ProgramList :proList="proList" />
      </tab-content-item>
    </tab-content> -->
    <el-tabs v-model="active" @tab-click="handleCurrentClickTab(active)">
      <el-tab-pane name="#intro" v-if="showTabItem.includes('intro')" :label="$t('Events.97c@introduce')">
        <Intro />
      </el-tab-pane>
      <el-tab-pane name="#terms" v-if="showTabItem.includes('terms')" label="Terms & Conditions">
        <Terms />
      </el-tab-pane>
      <el-tab-pane name="#syllabus" v-if="showTabItem.includes('syllabus')">
        <span @click="courseTab()" slot="label">Syllabus</span>
        <Syllabus :sylla="syllabusList" />
      </el-tab-pane>
      <el-tab-pane name="#COC" v-if="showTabItem.includes('coc')" :label="$t('Events.83c@staff')">
        <COC :type="type" />
      </el-tab-pane>
      <el-tab-pane name="#allProgram" v-if="showTabItem.includes('allProgram') && package_type != 1">
        <span @click="eventProgram()" slot="label">All Program</span>
        <AllProgram :allProgramList="allProgramList" />
      </el-tab-pane>
      <el-tab-pane name="#Media" v-if="showTabItem.includes('media')" :label="$t('Events.f87@media')">
        <Media @changeCurrentMediaType="getCurrentMediaType" />
      </el-tab-pane>
      <el-tab-pane name="#Comment" v-if="showTabItem.includes('comment')" :label="$t('Events.2ea@comment')">
        <Comment :type="type" />
      </el-tab-pane>
      <el-tab-pane name="#Questions" v-if="showTabItem.includes('questions')" :label="$t('Events.b9a@questions')">
        <Questions :type="type" />
      </el-tab-pane>
      <el-tab-pane name="#Attendees" v-if="showTabItem.includes('attendees')" :label="$t('Events.08f@attendess')">
        <Attendees :type="type" />
      </el-tab-pane>
      <el-tab-pane name="#Certificate" v-if="showTabItem.includes('certificate')" :label="$t('Events.c8e@certificate')">
        <Certificate />
      </el-tab-pane>
      <el-tab-pane name="#Results" v-if="showTabItem.includes('result')" label="Results">
        <Result />
      </el-tab-pane>
      <el-tab-pane name="#JCIprogramList" v-if="showTabItem.includes('JCIprogramList') && has_program">
        <span slot="label" @click="getProgram()">JCI Signature Program</span>
        <ProgramList :proList="proList" />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import Intro from "./components/Intro";
import Terms from "./components/Terms";
import COC from "./components/COC";
import AllProgram from "./components/AllProgram";
import Syllabus from "./components/Syllabus";
import Media from "./components/Media";
import Comment from "./components/Comment";
import Questions from "./components/Questions";
import Attendees from "./components/Attendees";
import Certificate from "./components/Certificate";
import Result from "./components/Result";
import ProgramList from "./components/Program";

import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      active: "#intro",
      syllabusList: [],
      proList: [],
      allProgramList: {},
    };
  },

  props: {
    showTabItem: {
      type: Array,
    },
    type: {
      type: Number,
    },
  },
  components: {
    Intro,
    Terms,
    COC,
    AllProgram,
    Syllabus,
    Media,
    Comment,
    Questions,
    Attendees,
    Certificate,
    Result,
    ProgramList,
  },

  computed: {
    ...mapState({
      typeId: (state) => state.Process.typeId,
      has_program: (state) => state.Process.headerData.has_program,
      package_type: (state) => state.Process.headerData.package_type,
    }),
  },
  methods: {
    ...mapActions(["getProjectTabData"]),
    ...mapMutations(["checkIndex"]),
    getCurrentMediaType(type) {
      // console.log(type);
      this.mediaType = type;
      this.handleCurrentClickTab("#Media");
    },
    // All program
    eventProgram(v) {
      this.checkIndex();
      let type = this.$route.query.type;
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let ajaxType = {
        1: "projectProgram",
        2: "eventProgram",
        3: "courseProgram",
        4: "meetingProgram",
      };
      this.$http[ajaxType[type]]({
          [typeId[type]]: this.$route.query.id,
          type: 1,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        .then((res) => {
          this.allProgramList = res.data.programs;
        });
    },
    // event关联program
    async getProgram() {
      let res = await this.$http.eventTab({
        event_id: this.$route.query.id,
        type: 6,
      });
      if (res.status == 200) {
        this.proList = res.data;
      }
    },
    // 课程章节
    async courseTab() {
      let res = await this.$http.courseTab({
        course_id: this.$route.query.id,
        type: "syllabus",
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      if (res.status == 200) {
        // console.log(res);
        this.syllabusList = res.data;
      }
    },
    // handleCurrentClickTab({ currentTarget: { hash } }){
    //   console.log({ currentTarget: { hash } });
    // }
    handleCurrentClickTab(id) {
      let currentTarget={};
      let hash = id;
      currentTarget.hash = id;
      // console.log({ currentTarget });
      let musterArr = ["#COC", "#Media", "", "#Comment", "#Attendees"];
      let routerType = this.$route.query.type;
      let ajaxType = {
        1: "projectTab",
        2: "eventTab",
        3: "courseTab",
        4: "meetingTab",
      };
      if (musterArr.indexOf(hash) >= 0) {
        let type = musterArr.indexOf(hash) + 1;
        this.getProjectTabData([
          {
            [this.typeId[routerType]]: this.$route.query.id,
            type: (hash == "#Media" && this.mediaType) || type,
            per_page: 10,
          },
          ajaxType[routerType],
        ]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .nav {
  width: 930px;
  padding-bottom: 5px;
  display: -webkit-box;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
@media (max-width: 500px) {
  ::v-deep .el-tabs__item{
    font-size: 11px;
    padding: 0 10px;
  }
  ::v-deep .el-card__body{
    padding: 10px;
  }
  .tabCard{
    font-size:12px
  }
}
</style>
