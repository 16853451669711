<template>
  <section class="Syllabus">
    <div v-for="(v, i) in sylla" :key="i">
      <div
        class="d-flex align-items-center justify-content-between title playGray"
      >
        <span :style="{ color: v.style_color }"
          >MODULE {{ v.number }}: {{ v.title }}</span
        >

        <!-- 视频 -->
        <div v-if="v.type == 'video'">
          <a :href="v.broadcast_link" target="_blank">
            <i style="font-size:22px" v-if="v.style_color == 'grey'" class="ri-play-circle-line"></i>
            <i v-else style="color: #ffba68;font-size:22px" class="ri-play-circle-line"></i>
          </a>
        </div>
        <!-- 线上 -->
        <div v-if="v.type == 'live'">
          <span v-if="v.date_status == 'future'">Attended</span>
          <!--  -->
          <img
            src="../../../../assets/images/course/live.png"
            v-else-if="v.date_status == 'be_being'"
            style="color: #ffba68"
            @click="borLink(v.id)"
          />
          <span v-else>{{ v.start_time }}</span>
        </div>
        <!-- 线下 -->
        <div v-if="v.type == 'offline'">
          <span v-if="v.join_status == '0'">Absent</span>
          <span v-else-if="v.join_status == '1'">Attended</span>
          <div v-else @click="courseModuleInlet(v.id)">
            <i
              style="color: #ffba68"
              class="ri-qr-code-line"
              v-b-modal.erweima
            ></i>
          </div>
        </div>
      </div>
      <div v-if="v.type == 'offline'">
        <p class="row pl-3">
          <span class="col-2">Date:</span>
          <span class="col">{{ v.start_time }}</span>
        </p>
        <p class="row pl-3">
          <span class="col-2">Place:</span>
          <span class="col">{{ v.address }}</span>
        </p>
      </div>
      <div v-else class="desc" style="color: #666">
        {{ v.introduce }}
      </div>
      <div v-if="v.module_file.length > 0" class="row">
        <div class="col-1" style="color: #ffba68; font-size: 30px">
          <i class="ri-folder-5-line"></i>
        </div>
        <div class="col-10 pt-3">
          <div v-for="(n, index) of v.module_file" :key="index">
            <a :href="n" target="_blank" rel="noopener noreferrer">
              <u>{{ n }}</u></a
            >
          </div>
        </div>
      </div>
    </div>
    <b-modal id="erweima" centered hide-footer>
      <div class="row mt-5 mb-5">
        <div class="col-3"></div>
        <div class="qrcode col-6 ml-3" ref="qrCodeUrl"></div>
      </div>
    </b-modal>
    <b-modal id="link" centered hide-footer>
      <div class="mb-5">
        <div>Please click the link to join the meeting</div>
        <div class="row">
          <i class="ri-map-pin-line col-1"></i>
          <a :href="broadcast_link" class="col">
            <u> {{ broadcast_link }} </u>
          </a>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  name: "",
  components: {},
  props: {
    sylla: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      broadcast_link: "",
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    borLink(id) {
      this.$http.courseModuleInlet({ id }).then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          this.$bvModal.show("link");
          this.broadcast_link = res.data.broadcast_link;
        }
      });
    },
    courseModuleInlet(id) {
      this.$http.courseModuleInlet({ id }).then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          this.creatQrCode(res.data.qr_code);
        }
      });
    },
    creatQrCode(text) {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: text, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.L,
      });
    },
  },
};
</script>
<style src="" lang="scss" scoped>
.Syllabus {
  .title {
    height: 50px;
    line-height: 50px;
    background: #f4f7f9;
    font-weight: 500;
    color: #333333;
    padding: 0 20px 0 15px;
    margin-bottom: 20px;
  }
  .playGray {
    color: #999999;
  }
  .desc {
    color: #666666;
    line-height: 24px;
  }
  .qrcode {
    display: inline-block;
    img {
      width: 300px;
      height: 300px;
      background-color: #fff; //设置白色背景色
      padding: 6px; // 利用padding的特性，挤出白边
      box-sizing: border-box;
    }
  }
}
</style>
