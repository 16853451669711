<template>
  <section
    v-infinite-scroll="infiniteData"
    infinite-scroll-disabled="addressDis"
    infinite-scroll-delay="1000"
    infinite-scroll-immediate="false"
  >
    <el-row
      :gutter="10"
      style="height: 450px; overflow: auto"
      v-if="cocList.length"
    >
      <el-col :xs="8" :sm="6" v-for="(v, i) in cocList" :key="i">
        <el-card class="coc-card" style="width: 100%">
          <router-link
            :to="{ path: '/profile', query: { user_id: v.user_id } }"
          >
            <div class="avatar">
              <el-avatar
                style="width: 80px; height: 80px; border: 1px solid #f1f1f1"
                size="large"
                :src="v.avatar | avatarFilter"
              ></el-avatar>
            </div>
            <h3 class="text-overflow">{{ v.nick_name }}</h3>
            <p class="text-overflow">{{ v.coc_role ? v.coc_role : "-" }}</p>
          </router-link>
        </el-card>
      </el-col>
    </el-row>
    <div
      class="d-flex justify-content-center align-items-center"
      style="margin-top: 100px;background:#fff;padding:30px 0;"
      v-else
    >
      <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { InfiniteScroll } from "element-ui";
export default {
  props: {
    type: {
      type: Number,
    },
  },
  data() {
    return {
      // cocList: [],
      page: 1,
      // addressDis: false,
    };
  },
  directives: {
    "infinite-scroll": InfiniteScroll,
  },

  components: {},
  filters: {
    avatarFilter(avatar) {
      return avatar ? avatar : require("@/assets/images/public/user.png");
    },
  },
  computed: {
    ...mapState({
      cocList: (state) => {
        return state.Process.tabListData || [];
      },
      addressDis: (state) => {
        return state.Process.cocAddressDis;
      },
      currentPageSize: (state) => {
        return state.Process.currentPageSize;
      },
    }),
  },
  created() {},

  methods: {
    ...mapActions(["getProjectTabData"]),

    infiniteData() {
      // console.log(1111111, this.page);
      this.page++;
      this._getProjectTabData();
    },
    _getProjectTabData() {
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let ajaxType = {
        1: "projectTab",
        2: "eventTab",
        3: "courseTab",
        4: "meetingTab",
      };
      let data = {
        [typeId[this.type]]: this.$route.query.id,
        type: 1,
        per_page: 12,
        page: this.page,
      };
      this.getProjectTabData([data, ajaxType[this.type]]);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .coc-card {
    text-align: center;
    margin-bottom: 10px;

    .avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    h3 {
      color: #333;
      font-size: 16px;
    }

    p {
      color: #999;
    }

    .text-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
@media (max-width: 576px) {
  .coc-card {
    text-align: center;
    margin-bottom: 10px;
    ::v-deep .el-card__body{
      padding: 10px;
    }

    .avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    h3 {
      color: #333;
      font-size: 12px;
    }

    p {
      color: #999;
    }

    .text-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
