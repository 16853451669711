<template>
  <section
    v-infinite-scroll="infiniteData"
    infinite-scroll-disabled="addressDis"
    infinite-scroll-delay="1000"
    infinite-scroll-immediate="false"
    style="height: 450px; overflow-x: hidden; overflow-y: auto"
  >
    <el-row :gutter="10" v-if="attendeesList.length">
      <el-col :span="6" v-for="(v, i) in attendeesList" :key="i">
        <el-card class="attend-card" style="width: 100%">
          <div class="avatar">
            <el-avatar :src="v.avatar"></el-avatar>
          </div>
          <h3 class="text-overflow">{{ v.nick_name }}</h3>
          <p class="text-overflow">{{ v.country ? v.country : "-" }}</p>
        </el-card>
      </el-col>
    </el-row>
    <div
      class="d-flex justify-content-center align-items-center"
      style="background:#fff;margin-top: 100px"
      v-else
    >
      <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { InfiniteScroll } from "element-ui";
export default {
  props: {
    type: {
      type: Number,
    },
  },

  data() {
    return {
      page: 1,
    };
  },

  directives: {
    "infinite-scroll": InfiniteScroll,
  },

  components: {},

  computed: {
    ...mapState({
      attendeesList: (state) => {
        return state.Process.tabListData || [];
      },
      addressDis: (state) => {
        return state.Process.addressDis;
      },
    }),
  },

  created() {
    // this._fnGetProjectTabData()
    this.$bus.$on("updateAttendees", () => {
      this._fnGetProjectTabData();
    });
  },

  methods: {
    ...mapActions(["getProjectTabData"]),
    infiniteData() {
      // console.log(1111111, this.page);
      this.page++;
      this._fnGetProjectTabData();
    },
    _fnGetProjectTabData() {
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let ajaxType = {
        1: "projectTab",
        2: "eventTab",
        3: "courseTab",
        4: "meetingTab",
      };
      let data = {
        [typeId[this.type]]: this.$route.query.id,
        type: 5,
        per_page: 12,
        page: this.page,
      };

      this.getProjectTabData([data, ajaxType[this.type]]);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .attend-card {
    text-align: center;
    margin-bottom: 10px;

    .avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .el-avatar {
        width: 80px;
        height: 80px;
        border: 1px solid #f1f1f1;
      }
    }

    h3 {
      color: #333;
      font-size: 16px;
    }

    p {
      color: #999;
    }

    .text-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
@media (max-width: 576px) {
  .attend-card {
    text-align: center;
    margin-bottom: 10px;

    .avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .el-avatar {
        width: 40px;
        height: 40px;
        border: 1px solid #f1f1f1;
      }
    }

    h3 {
      color: #333;
      font-size: 12px;
    }

    p {
      color: #999;
    }

    .text-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
