<template>
  <div class="comment">
    <div class="score">
      <h3>{{ commentLastScore.score_avg }}</h3>
      <el-rate
        :value="parseInt(commentLastScore.score_avg)"
        void-color="#FFBA68"
      >
      </el-rate>
      <p>{{ commentLastScore.count }} {{ $t("Events.2ea@comment") }}</p>
    </div>
    <div class="post_comment d-flex" v-if="postComment">
      <el-avatar
        :src="userInfo.avatar"
        fit="cover"
      ></el-avatar>
      <div class="right_box" style="margin-left: 10px; width: 95%">
        <el-rate v-model="score" void-color="#FFBA68" class="my-2" />
        <el-input
          type="textarea"
          :rows="5"
          :placeholder="$t('Events.677@say_something')"
          v-model="content"
          style="width: 100%"
          resize="none"
        >
        </el-input>
        <div class="d-flex justify-content-end">
          <el-button type="primary" class="mt-2" @click="projectAddComment">{{
            $t("Events.048@send")
          }}</el-button>
        </div>
      </div>
    </div>
    <ul class="p-0 m-0 comment-list">
      <li v-for="(v, i) in commentList" :key="i">
        <el-avatar :src="v.avatar"></el-avatar>
        <div class="comment-content">
          <span
            ><b>{{ v.nick_name }}</b></span
          >
          <el-rate :value="v.score" disabled void-color="#FFBA68"> </el-rate>
          <p class="m-0">{{ v.content }}</p>
        </div>
        <div class="time_delete">
          <p class="m-0">{{ v.time }}</p>
          <i
            class="el-icon-delete"
            v-if="v.is_delete"
            @click="projectDeleteComment(v.id, i)"
          ></i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    type: {
      type: Number,
    },
  },
  data() {
    return {
      value2: 0,
      score: 0,
      content: "",
    };
  },

  computed: {
    ...mapState({
      postComment: (state) => state.Process.postComment,
      userInfo: (state) => state.Public.userInfo,
      commentList: (state) => state.Process.tabListData,
      commentLastScore: (state) => state.Process.commentLastScore,
      typeId: (state) => state.Process.typeId,
    }),

    computedType() {
      let type = this.$route.query.type;
      if (type == 1) return "Project";
      if (type == 2) return "Event";
      if (type == 3) return "Course";
      if (type == 4) return "Meeting";
    },
  },

  components: {},

  created() {},

  methods: {
    ...mapActions(["getProjectTabData"]),
    async projectAddComment() {
      let ajaxType = {
        1: "projectAddComment",
        2: "eventAddComment",
        3: "courseAddComment",
        4: "meetingAddComment",
      };
      let res = await this.$http[ajaxType[this.type]]({
        [this.typeId[this.type]]: this.$route.query.id,
        score: this.score,
        content: this.content,
      });
      if (res.status == 200) {
        this.$store.commit("postCommentData", 0);
        this.$message.success(this.$t("Events.1a9@comment_successful_p"));
      }
    },
    _getProjectTabData() {
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let ajaxType = {
        1: "projectTab",
        2: "eventTab",
        3: "courseTab",
        4: "meetingTab",
      };
      let data = {
        [typeId[this.type]]: this.$route.query.id,
        type: 4,
        per_page: 12,
        page: this.page,
      };
      this.getProjectTabData([data, ajaxType[this.type]]);
    },

    projectDeleteComment(comment_id, i) {
      let ajaxType = {
        1: "projectDeleteComment",
        2: "eventDeleteComment",
        3: "courseDeleteComment",
        4: "meetingDeleteComment",
      };
      this.$confirm(this.$t("Events.48a@this_operation_will_"), "Tips", {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t("Events.de9@confirm"),
        cancelButtonText: this.$t("Events.8f0@cancel"),
        type: "warning",
      }).then(async () => {
        let res = await this.$http[ajaxType[this.type]]({
          comment_id,
        });
        if (res.status == 200) {
          this.$bus.$emit(`update${this.computedType}Detail`);
          this._getProjectTabData();
          this.$store.commit("postCommentData", 1);
        }
      });
    },
  },
  beforeDestroy() {
    this.$bus.$off(`update${this.computedType}Detail`);
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .comment {
    .score {
      padding: 20px;
      text-align: center;

      h3 {
        font-size: 42px;
        color: #333;
      }

      p {
        color: #999;
      }
    }
    .post_comment{
      .el-avatar{
        width: 40px; 
        height: 40px;
      }
    }
    .comment-list {
      > li {
        display: flex;
        // justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #f1f1f1;

        .comment-content {
          margin-left: 20px;

          > span {
            color: #333;
          }
        }

        .time_delete {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          color: #999;

          i {
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .comment {
    .score {
      padding: 20px;
      text-align: center;

      h3 {
        font-size: 21px;
        color: #333;
      }

      p {
        color: #999;
      }
    }
    ::v-deep .el-rate__icon {
      font-size: 14px;
    }
    .post_comment{
      .el-avatar{
        width: 20px; 
        height: 20px;
      }
      .right_box{
        .el-textarea{
          font-size: 12px;
        }
        .el-button {
          width: 50px;
          height: 23px;
          font-size: 12px;
          padding: 6px 15px;
        }
      }
    }
    .comment-list {
      > li {
        display: flex;
        // justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #f1f1f1;

        .comment-content {
          margin-left: 20px;

          > span {
            color: #333;
          }
        }

        .time_delete {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          color: #999;

          i {
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
