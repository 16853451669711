<template>
  <section class="pub-media">
    <div class="self-tab">
      <span
        @click="handleChangeMediaType(2)"
        :class="{ active: activeVal == 2 }"
        >{{ $t("Events.a36@photo") }}</span
      >
      <span
        @click="handleChangeMediaType(3)"
        :class="{ active: activeVal == 3 }"
        >{{ $t("Events.f50@video") }}</span
      >
    </div>
    <el-row v-show="activeVal == 2" :gutter="15">
      <el-col :xs="8" :sm="6" v-for="(v, i) in photoList" :key="i">
        <el-image :src="v.path" fit="cover" :preview-src-list="computedImgBox">
        </el-image>
      </el-col>
      <b-card v-if="!photoList">
        <div
          class="d-flex justify-content-center align-items-center"
          style="height: 200px; background:#fff;margin-top: -20px"
        >
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
        </div>
      </b-card>
    </el-row>
    <el-row v-show="activeVal == 3" :gutter="15">
      <el-col :xs="8" :sm="6" v-for="(v, i) in videoList" :key="i">
        <div class="video-box">
          <video :src="v.path" controls></video>
        </div>
      </el-col>
      <b-card v-if="!videoList">
        <div
          class="d-flex justify-content-center align-items-center"
          style="height: 200px; background:#fff;margin-top: -20px"
        >
          <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
        </div>
      </b-card>
    </el-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      activeVal: 2,
    };
  },
  computed: {
    ...mapState({
      photoList: (state) => state.Process.tabListData,
      videoList: (state) => state.Process.tabListData,
    }),

    computedImgBox() {
      let photoList = this.photoList;
      let newArr = [];
      photoList.forEach((path) => {
        newArr.push(path.path);
      });
      return newArr;
    },
  },

  created() {},

  methods: {
    handleChangeMediaType(type) {
      this.activeVal = type;
      this.$emit("changeCurrentMediaType", type);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .pub-media {
    .el-row {
      margin-top: 20px;
    }
    .self-tab {
      display: flex;
      padding: 10px 0;
      font-size: 16px;

      > span {
        display: block;
        width: 90px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        color: #50b5ff;
        border-radius: 5px;
        cursor: pointer;

        &.active {
          background-color: #50b5ff;
          color: #fff;
        }
      }
    }
    .el-image {
      width: 100%;
      height: 150px;
      border-radius: 2.5px;
    }
    .video-box {
      width: 100%;
      height: 200px;

      > video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media (max-width: 576px) {
  .pub-media {
    .self-tab {
      display: flex;
      padding: 10px 0;

      > span {
        display: block;
        width: 55px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 20px;
        color: #50b5ff;
        border-radius: 3px;
        cursor: pointer;

        &.active {
          background-color: #50b5ff;
          color: #fff;
        }
      }
    }
    ::v-deep .el-col {
      padding-bottom: 10px;
    }
    .el-image {
      width: 100%;
      height: 80px;
      border-radius: 2.5px;
    }
    .video-box {
      width: 100%;
      height: 80px;
      padding-bottom: 10px;

      > video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
