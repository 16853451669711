<template>
  <section>
    <div class="result">
      <h3>
        {{ result.result && result.result.award_title }}
      </h3>
      <span
        >Winner number: {{ result.result && result.result.winner_number }}</span
      >
      <span>Bonus: {{ result.result && result.result.amount }}</span>
      <el-button
        v-show="result.result && result.result.winner_count"
        @click="dialogVisible = true"
        style="width: 120px; border-radius: 6px"
        type="primary"
        plain
        >Winners</el-button
      >
    </div>

    <ul class="p-0 past-result">
      <li class="mb-2" v-for="(v, i) in result.past" :key="i">
        <h3>
          {{ v.award_title }}
        </h3>
        <i @click="toResultDetail(v)" class="ri-link"></i>
      </li>
    </ul>
    <el-dialog title="Winner" :visible.sync="dialogVisible" width="50%">
      <el-row :gutter="10">
        <el-col
          v-for="(v, i) in result.result && result.result.winner_list"
          :key="i"
          :span="6"
        >
          <el-card class="winner-card" style="width: 100%">
            <div class="avatar">
              <el-avatar
                style="width: 80px; height: 80px"
                size="large"
                :src="v.avatar | avatarFilter"
              ></el-avatar>
            </div>
            <h3>{{ v.nick_name }}</h3>
            <p>{{ v.country }}</p>
          </el-card>
        </el-col>
      </el-row>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState({
      result: (state) => state.Process.result,
    }),
    computedType() {
      let type = this.$route.query.type;
      if (type == 1) return "Project";
      if (type == 2) return "Event";
      if (type == 3) return "Course";
      if (type == 4) return "Meeting";
    },
  },

  watch: {
    $route(to, from) {
      // console.log(to, from);
      if (to.query.id != from.query.id) {
        this.$bus.$emit(`update${this.computedType}Detail`);
      }
    },
  },

  filters: {
    avatarFilter(avatar) {
      return avatar ? avatar : require("@/assets/images/public/user.png");
    },
  },
  components: {},

  created() {},
  activated() {
    // console.log(555);
  },
  methods: {
    showWinners() {
      this.dialogVisible = true;
    },
    toResultDetail(v) {
      // console.log(v);
      let type = this.$route.query.type;
      let path = {
        1: "/project-detail",
        2: "/event-detail",
        3: "/course-detail",
        4: "/meeting-details",
      };
      this.$router.push({ path: path[type], query: { id: v.id, type: type } });
      this.$bus.$emit(`update${this.computedType}Detail`);
    },
  },
  beforeDestroy() {
    this.$bus.$off(`update${this.computedType}Detail`);
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .result {
    padding: 20px;
    background-color: #f5fbff;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    > h3 {
      font-size: 16px;
      color: #333;
    }
    > span {
      color: #666;
    }
    .winners {
      padding: 15px 25px;
      border: 1px solid #50b5ff;
      color: #50b5ff;
      border-radius: 6px;
      font-size: 16px;
      line-height: 1;
    }
  }

  .past-result {
    > li {
      background: #f8f8f8;
      padding: 20px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > h3 {
        color: #333;
        font-size: 16px;
      }
      > i {
        font-size: 26px;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .winner-card {
    text-align: center;
    margin-bottom: 10px;
    .avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    h3 {
      color: #333;
      font-size: 16px;
    }

    p {
      color: #999;
    }
  }
}
@media (max-width: 576px) {
  .result {
    padding: 10px;
    background-color: #f5fbff;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    > h3 {
      font-size: 14px;
      color: #333;
    }
    > span {
      color: #666;
      font-size: 12px;
    }
    .winners {
      padding: 8px 10px;
      border: 1px solid #50b5ff;
      color: #50b5ff;
      border-radius: 6px;
      font-size: 12px;
      line-height: 1;
    }
  }

  .past-result {
    > li {
      background: #f8f8f8;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > h3 {
        color: #333;
        font-size: 14px;
      }
      > i {
        font-size: 12px;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .winner-card {
    text-align: center;
    margin-bottom: 10px;
    .avatar {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    h3 {
      color: #333;
      font-size: 12px;
    }

    p {
      color: #999;
    }
  }
}
</style>
