<template>
  <div class="Main">
    <!-- <div class="proList" v-for="(item,index) of proList" :key="index"> -->
      <!-- <div class="proList">
        <h4>{{item.title}}</h4>
      </div> -->
      <!-- <div class="content"> -->
        <div
          class="itemCard"
          v-for="(v, i) of proList"
          :key="i"
          @click="goDetails(v)"
        >
          <div class="title" :title="v.title">
            {{v.title}}
          </div>
          <div class="org" :title="v.organization">{{v.organization}}</div>
          <div class="date" :title="v.registration_day">
            {{v.registration_day}}
          </div>
        </div>
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    proList: {
      type: Array,
    },
  },
  methods: {
    goDetails(v) {
      // console.log(v);
      this.$router.push(`award-detail?id=${v.id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .Main {
    display: flex;
    flex-wrap: wrap;
  // .content {
  //   display: flex;
  //   flex-wrap: wrap;
    .itemCard {
      cursor: pointer;
      width: 300px;
      height: 120px;
      padding: 5px 20px;
      margin: 5px 4px;
      background: #f8f8f8;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      div {
        margin: 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title {
        font-size: 18px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Montserrat, Montserrat-Light;
        color: #333333;
      }
      .org {
        font-size: 18px;
        font-family: Montserrat, Montserrat-Light;
        font-weight: 300;
        color: #333333;
      }
      .date {
        font-size: 14px;
        font-family: Montserrat, Montserrat-Light;
        font-weight: 300;
        color: #999999;
      }
    }
  // }
}
}
@media (max-width: 576px) {
  .Main {
    display: flex;
    flex-wrap: wrap;
  // .content {
  //   display: flex;
  //   flex-wrap: wrap;
    .itemCard {
      cursor: pointer;
      width: 170px;
      height: 66.5px;
      padding: 5px 10px;
      margin: 5px 5px;
      background: #f8f8f8;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      div {
        margin: 4px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .title {
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Montserrat, Montserrat-Light;
        color: #333333;
      }
      .org {
        font-size: 12px;
        font-family: Montserrat, Montserrat-Light;
        font-weight: 300;
        color: #333333;
      }
      .date {
        font-size: 14px;
        font-family: Montserrat, Montserrat-Light;
        font-weight: 300;
        color: #999999;
      }
    }
  // }
}
}

</style>
