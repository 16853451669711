<template>
  <el-card style="margin-top: 20px">
    <tab-nav :tabs="true">
      <tab-nav-items
        v-show="special.length"
        :active="active == 'special'"
        href="#special"
        title="Special"
      />
      <tab-nav-items
        v-show="file.length"
        :active="active == 'file'"
        href="#file"
        title="File"
      />
    </tab-nav>

    <tab-content>
      <tab-content-item :active="active == 'special'" id="special">
        <el-row :gutter="10">
          <el-col v-for="(item, index) in special" :key="index" :span="8">
            <div class="special-card">
              <div>
                <el-avatar
                  style="width: 70px; height: 70px"
                  size="large"
                  :src="item.avatar"
                ></el-avatar>
              </div>
              <div class="special-info">
                <h3>{{ item.name }}</h3>
                <span>{{ item.coc_role }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </tab-content-item>
      <!-- file -->
      <tab-content-item :active="active == 'file'" id="file">
        <div class="file_box">
          <div>
            <i class="ri-folder-2-fill"></i>
            {{ $t("Events.c5d@please_click_the_lin") }}:
          </div>
          <ul>
            <li v-for="link in file" :key="link">
              <a :href="link" target="_blank">{{ link }}</a>
            </li>
          </ul>
        </div>
      </tab-content-item>
    </tab-content>
  </el-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {

    };
  },
  props: {
    special: {
      type: Array
    },
    file: {
      type: Array
    }
  },

  computed: {
    active () {
      return this.special.length ? "special" : this.file.length ? 'file' : '';
    }
  },

  components: {},

  created () { },

  methods: {}
}

</script>
<style lang="scss" scoped>
.special-card {
  background: #f8f8f8;
  padding: 20px;
  border: 1px solid #f1f1f1;
  align-items: center;
  border-radius: 6px;
  display: flex;
  margin-bottom: 10px;
  .special-info {
    padding-left: 20px;

    > h3 {
      font-size: 16px;
      color: #333;
    }

    span {
      font-size: 14px;
      color: #999;
    }
  }
}

.file_box {
  background: #fff;
  padding: 10px 30px;
  > div {
    color: #666;
    display: flex;
    align-items: center;
    > i {
      font-size: 35px;
      color: #f9d35c;
      margin-right: 30px;
    }
  }

  > ul {
    margin-left: 30px;
    > li > a {
      text-decoration: underline;
    }
  }
}
</style>