<template>
  <div>
    <ul class="cert_list p-0" v-if="certificate">
      <li v-for="(v, i) in certificate" :key="i">
        <div class="img-box">
          <el-image :src="v.image" fit="cover"></el-image>
        </div>
        <div class="cert-info">
          <h3>{{ v.title }}</h3>
          <span>{{ v.introduce }}</span>
        </div>

        <div
          class="test-btn"
          v-if="package_type != 2"
          v-show="
            !is_register && project_single.is_buy && project_single.is_attend
          "
        >
          <el-button
            @click="jumpTest(v.id, package_type)"
            size="small"
            type="primary"
            plain
            >Check</el-button
          >
        </div>
        <div class="test-btn" v-if="package_type == 2 && v.is_own == 1">
          <i
            style="font-size: 20px; color: #57b8ff"
            class="ri-checkbox-circle-line"
          ></i>
        </div>
      </li>
    </ul>
    <div v-else class="d-flex justify-content-center" style="margin-top: 60px;background:#fff;">
      <img style="width:250px;" src="../../../../assets/images/group/empty1.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      certificate: (state) => state.Process.certificate,
      project_single: (state) => state.Process.project_single,
      // 是否注册
      is_register: (state) => state.Process.is_register,
      package_type: (state) => state.Process.headerData.package_type,
    }),
  },

  components: {},

  created() {},

  methods: {
    jumpTest(id, packagetype) {
      this.$router.push({ path: "/test-detail", query: { id, packagetype } });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .cert_list {
    min-height: 500px;
    .img-box {
      .el-image {
        width: 100%;
        height: 100%;
      }
    }
    > li {
      padding: 20px;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      .img-box {
        border: 1px solid #ddd;
        width: 148px;
        height: 90px;
        border-radius: 6px;
        overflow: hidden;
        > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .cert-info {
        padding-left: 10px;
        > h3 {
          font-size: 16px;
          color: #333;
        }

        > span {
          font-size: 12px;
          color: #999999;
        }
      }

      .test-btn {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
@media (max-width: 576px) {
  .cert_list {
    min-height: 200px;
    > li {
      padding: 5px;
      display: flex;
      border-bottom: 1px solid #f1f1f1;
      .img-box {
        border: 1px solid #ddd;
        width: 69px;
        height: 42.5px;
        border-radius: 6px;
        overflow: hidden;
        > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .cert-info {
        padding-left: 10px;
        > h3 {
          font-size: 12px;
          color: #333;
        }

        > span {
          font-size: 12px;
          color: #999999;
        }
      }

      .test-btn {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
</style>
