<template>
  <section>
    <div v-if="!Array.isArray(allProgramList)">
      <div class="program-header d-flex justify-content-between">
        <div class="d-flex">
          <p class="m-0">
            {{ Object.keys(this.allProgramList)[index] }}
            <span class="smallAddress" style="color: #b1b6ba"
              >({{
                allProgramList[Object.keys(allProgramList)[index]] &&
                allProgramList[Object.keys(allProgramList)[index]][0]
                  .time_zone_2
              }})</span
            >
          </p>
        </div>
        <div class="program-btn">
          <el-button
            :type="index ? '' : 'info'"
            plain
            :disabled="!index"
            style="border: none"
            @click="changeIndex(0)"
          >
            {{ $t("Events.99e@previous") }}</el-button
          >
          <el-button
            :type="
              index != Object.keys(this.allProgramList).length - 1 ? '' : 'info'
            "
            plain
            :disabled="index == Object.keys(this.allProgramList).length - 1"
            style="border: none;"
            @click="changeIndex(1)"
          >
            Next Day
          </el-button>
        </div>
      </div>
      <div>
        <section
          v-for="(v, i) in allProgramList[
            Object.keys(this.allProgramList)[index]
          ]"
          :key="i"
          class="program-content"
        >
          <ul class="p-0 program-list">
            <li>
              <div>
                <h3 style="cursor: pointer" @click="toProgramDetail(v)">
                  {{ v.program_title }}
                  <span
                    :class="{
                      MeetingTag: v.model_type == 4,
                      EventTag: v.model_type == 2,
                      courseTag: v.model_type == 3,
                      projectTag: v.model_type == 1,
                      noneTag: v.model_type == 0,
                    }"
                    class="tag"
                    v-if="v.is_cron"
                  >
                    {{ judgeCurrentType(v) }}
                  </span>
                  <!-- <span class="ml-2"
                      >({{ v.program_count }}/{{ v.max_attendee }})</span
                    > -->
                </h3>
                <span
                  ><i class="ri-time-line"></i>{{ v.time1 }}
                  <!-- <span style="color: #b1b6ba">({{ v.time_zone_2 }})</span> -->
                </span>
                <section v-if="!(v.model_type == 3 && v.package_type == 1)">
                  <span v-if="v.type == 3">
                    <i class="ri-map-pin-line"></i>{{ v.venue }} {{ v.room }} /
                    Online
                  </span>
                  <span v-else-if="v.type != 2 && v.room"
                    ><i class="ri-map-pin-line"></i>{{ v.venue }}
                    {{ v.room }}</span
                  >
                  <span v-else-if="v.type_desc"
                    ><i class="ri-map-pin-line"></i>{{ v.type_desc }}</span
                  >
                </section>
                <span
                  ><i class="ri-lock-unlock-line"></i
                  >{{ Object.values(v.attendee_tags).join("，") }}
                </span>
                <span v-if="v.special_guest.length"
                  ><i class="ri-user-3-line"></i>
                  {{ v.special_guest.map((n) => n.name).join("，") }}
                </span>
                <section v-if="!(v.model_type == 3 && v.package_type == 1)">
                  <span
                    v-if="
                      v.type != 1 && (v.broadcast_link || v.conference_link)
                    "
                    class="d-flex align-items-center"
                  >
                    <i class="ri-link"></i>
                    <div class="d-flex flex-column">
                      <span
                        v-if="v.broadcast_link"
                        @click="userHasPowerClickLink(v)"
                        class="link_blue"
                        >{{ $t("Events.dcf@click_here_to_view") }}</span
                      >
                      <a
                        class="link_blue"
                        @click="conferLinkLink(v.conference_link)"
                        v-if="v.conference_link"
                        target="__blank"
                        >{{ $t("Events.e6e@click_here_to_join_c") }}</a
                      >
                    </div>
                  </span>
                </section>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
    <div v-else style="background:#fff;" class="container690">
      <img
        src="@/assets/images/group/empty1.png"
        alt=""
        style="width:250px;margin: 100px auto; display: block"
      />
    </div>
  </section>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: {
    allProgramList: {
      type: Object,
      default: [],
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      index: (state) => {
        if (state.Process.tabIndex >= 1) {
          return state.Process.tabIndex;
        } else {
          return 0;
        }
      },
    }),
  },
  created() {},
  methods: {
    ...mapMutations(["changeIndex", "clearCheckArray", "cancelPay"]),
    judgeCurrentType(item) {
      let type = item.model_type;
      return type == 1
        ? "Project"
        : type == 2
        ? "Event"
        : type == 3
        ? "Course"
        : type == 4
        ? "Meeting"
        : "";
    },
    toProgramDetail(item) {
      let path = {
        1: "/project-detail",
        2: "/event-detail",
        3: "/course-detail",
        4: "/meeting-details",
      };
      if (item.is_cron) {
        this.$router.push({
          path: path[item.model_type],
          query: {
            id: item.model_id,
            type: item.model_type,
          },
        });
        location.reload();
      }
    },
    conferLinkLink(link) {
      // console.log(link.indexOf("http"));
      if (link.indexOf("http") >= 0) window.open(link, "_blank");
      if (link.indexOf("http") < 0) window.open(`http://${link}`, "_blank");
    },
    async userHasPowerClickLink(item) {
      let ajaxType = {
        1: "userHasPowerClickLink",
        2: "eventUserHasPowerClickLink",
        3: "courseUserHasPowerClickLink",
        4: "meetingUserHasPowerClickLink",
      };
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let type = this.$route.query.type;
      let token = localStorage.getItem("token");
      let getToken = token ? token : "";
      if (!getToken) {
        this.$router.push("/");
        return;
      }
      let res = await this.$http[ajaxType[type]]({
        [typeId[type]]: item.model_id,
        my_schedule_id: item.my_schedule_id,
        my_schedule_model_type: item.my_schedule_model_type,
        link_type: "broadcast_link",
      });
      if (res.status == 200) {
        // vimeo.com就在项目里，外链跳出去
        if (item.broadcast_link.indexOf("vimeo.com") > 0) {
          this.$router.push({
            path: type == 2 ? "/video-chat" : "/video",
            //modelType ==2，跳'video-chat'
            query: {
              id: item.model_id,
              my_schedule_id: item.my_schedule_id,
              type: this.$route.query.type,
              my_schedule_model_type: item.my_schedule_model_type,
              program_id: item.program_id,
              src: item.broadcast_link,
            },
          });
        } else {
          if (item.broadcast_link.indexOf("http") >= 0)
            window.open(item.broadcast_link, "_blank");
          if (item.broadcast_link.indexOf("http") < 0)
            window.open(`http://${item.broadcast_link}`, "_blank");
          // window.open(item.broadcast_link, '_blank')
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .program-header {
    display: flex;
    padding: 10px;
    background-color: #f5fbff;
    align-items: center;

    > div i {
      font-size: 16px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #666;
      font-size: 16px;
    }

    > div.program-btn {
      /* display: flex; */
      /* flex-grow: 1; */
      /* justify-content: flex-end; */

      span {
        display: block;
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }
  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 10px;
        padding-left: 64px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 14px;
          left: 26px;
        }

        > div {
          display: flex;
          flex-direction: column;
          .link_blue {
            text-decoration: underline;
            color: #50b5ff;
            padding: 0;
            cursor: pointer;
          }

          > h3 {
            font-size: 16px;
            color: #333;

            > .tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }
            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }
            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 8px 0;
            line-height: 1;

            > i {
              font-size: 17px;
              padding-right: 10px;
            }
          }
        }

        > div.i-tooltips {
          i {
            font-size: 22px;
            color: #50b5ff;
            cursor: pointer;
          }
        }
        > div.i-remove {
          i {
            font-size: 22px;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .program-header {
    display: flex;
    padding: 10px;
    background-color: #f5fbff;
    align-items: center;
    .smallAddress {
      display: none;
    }
    > div i {
      font-size: 12px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #000;
      font-size: 12px;
      font-weight: 300;
    }

    > div.program-btn {
      min-width: 150px;
      .el-button{
        width: 70px;
        height: 30px;
        font-size: 12px;
        padding: 6px;
        background: #ffffff;
        border-radius: 2.5px;
      }
    }
  }
  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 10px;
        padding-left: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 12px;
          left: 10px;
        }

        > div {
          display: flex;
          flex-direction: column;
          .link_blue {
            text-decoration: underline;
            color: #50b5ff;
            padding: 0;
            cursor: pointer;
          }

          > h3 {
            font-size: 12px;
            color: #333;

            > .tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }
            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }
            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 5px 0;
            font-size: 10px;
            line-height: 1;

            > i {
              font-size: 10px;
              padding-right: 5px;
            }
          }
        }

        > div.i-tooltips {
          i {
            font-size: 14px;
            color: #50b5ff;
            cursor: pointer;
          }
        }
        > div.i-remove {
          i {
            font-size: 14px;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
