var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(!Array.isArray(_vm.allProgramList))?_c('div',[_c('div',{staticClass:"program-header d-flex justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(Object.keys(this.allProgramList)[_vm.index])+" "),_c('span',{staticClass:"smallAddress",staticStyle:{"color":"#b1b6ba"}},[_vm._v("("+_vm._s(_vm.allProgramList[Object.keys(_vm.allProgramList)[_vm.index]] && _vm.allProgramList[Object.keys(_vm.allProgramList)[_vm.index]][0] .time_zone_2)+")")])])]),_c('div',{staticClass:"program-btn"},[_c('el-button',{staticStyle:{"border":"none"},attrs:{"type":_vm.index ? '' : 'info',"plain":"","disabled":!_vm.index},on:{"click":function($event){return _vm.changeIndex(0)}}},[_vm._v(" "+_vm._s(_vm.$t("Events.99e@previous")))]),_c('el-button',{staticStyle:{"border":"none"},attrs:{"type":_vm.index != Object.keys(this.allProgramList).length - 1 ? '' : 'info',"plain":"","disabled":_vm.index == Object.keys(this.allProgramList).length - 1},on:{"click":function($event){return _vm.changeIndex(1)}}},[_vm._v(" Next Day ")])],1)]),_c('div',_vm._l((_vm.allProgramList[
          Object.keys(this.allProgramList)[_vm.index]
        ]),function(v,i){return _c('section',{key:i,staticClass:"program-content"},[_c('ul',{staticClass:"p-0 program-list"},[_c('li',[_c('div',[_c('h3',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toProgramDetail(v)}}},[_vm._v(" "+_vm._s(v.program_title)+" "),(v.is_cron)?_c('span',{staticClass:"tag",class:{
                    MeetingTag: v.model_type == 4,
                    EventTag: v.model_type == 2,
                    courseTag: v.model_type == 3,
                    projectTag: v.model_type == 1,
                    noneTag: v.model_type == 0,
                  }},[_vm._v(" "+_vm._s(_vm.judgeCurrentType(v))+" ")]):_vm._e()]),_c('span',[_c('i',{staticClass:"ri-time-line"}),_vm._v(_vm._s(v.time1)+" ")]),(!(v.model_type == 3 && v.package_type == 1))?_c('section',[(v.type == 3)?_c('span',[_c('i',{staticClass:"ri-map-pin-line"}),_vm._v(_vm._s(v.venue)+" "+_vm._s(v.room)+" / Online ")]):(v.type != 2 && v.room)?_c('span',[_c('i',{staticClass:"ri-map-pin-line"}),_vm._v(_vm._s(v.venue)+" "+_vm._s(v.room))]):(v.type_desc)?_c('span',[_c('i',{staticClass:"ri-map-pin-line"}),_vm._v(_vm._s(v.type_desc))]):_vm._e()]):_vm._e(),_c('span',[_c('i',{staticClass:"ri-lock-unlock-line"}),_vm._v(_vm._s(Object.values(v.attendee_tags).join("，"))+" ")]),(v.special_guest.length)?_c('span',[_c('i',{staticClass:"ri-user-3-line"}),_vm._v(" "+_vm._s(v.special_guest.map(function (n) { return n.name; }).join("，"))+" ")]):_vm._e(),(!(v.model_type == 3 && v.package_type == 1))?_c('section',[(
                    v.type != 1 && (v.broadcast_link || v.conference_link)
                  )?_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"ri-link"}),_c('div',{staticClass:"d-flex flex-column"},[(v.broadcast_link)?_c('span',{staticClass:"link_blue",on:{"click":function($event){return _vm.userHasPowerClickLink(v)}}},[_vm._v(_vm._s(_vm.$t("Events.dcf@click_here_to_view")))]):_vm._e(),(v.conference_link)?_c('a',{staticClass:"link_blue",attrs:{"target":"__blank"},on:{"click":function($event){return _vm.conferLinkLink(v.conference_link)}}},[_vm._v(_vm._s(_vm.$t("Events.e6e@click_here_to_join_c")))]):_vm._e()])]):_vm._e()]):_vm._e()])])])])}),0)]):_c('div',{staticClass:"container690",staticStyle:{"background":"#fff"}},[_c('img',{staticStyle:{"width":"250px","margin":"100px auto","display":"block"},attrs:{"src":require("@/assets/images/group/empty1.png"),"alt":""}})])])}
var staticRenderFns = []

export { render, staticRenderFns }