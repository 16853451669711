<template>
  <section>
    <ul class="p-0 m-0 question-list">
      <li v-for="(v, i) in questionnaire" :key="i">
        <div class="question-content">
          <span
            ><b>{{ v.questionnaire_title }}</b></span
          >
          <p class="m-0">{{ v.total }} {{ $t("Events.b9a@questions") }}</p>
        </div>

        <!-- v.user_submit -->
        <el-button
          v-show="!v.user_submit"
          size="small"
          @click="getQestionList(v)"
          type="primary"
          >Enroll</el-button
        >
        <el-button v-show="v.user_submit" class="submit" size="small" disabled
          >Enrolled</el-button
        >
      </li>
    </ul>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" >
      <div class="simulate">
        <div class="simulatelist">
          <div
            class="blue_line"
            v-for="(item, index) in personalQuestionList"
            :key="index"
          >
            <!-- 填空题 -->
            <div class="fill_black mb-3" v-if="item.type == 3">
              <div class="mb-3">
                <span>{{ index + 1 }}.{{ item.topic }}</span>
              </div>
              <div class="vx-col sm:w-5/12">
                <el-input
                  placeholder="Fill in..."
                  v-model="item.user_answer"
                ></el-input>
              </div>
            </div>

            <!-- 单选题 -->
            <div class="single_chose mb-3" v-if="item.type == 1">
              <div class="mb-3">
                <span>{{ index + 1 }}.{{ item.topic }}</span>
              </div>
              <div>
                <el-radio-group v-model="item.user_answer">
                  <el-radio :label="i" v-for="(r, i) in item.option" :key="i">{{
                    r
                  }}</el-radio>
                </el-radio-group>
              </div>
            </div>

            <!-- 多选题 -->
            <div class="single_chose mb-3" v-if="item.type == 2">
              <div class="mb-3">
                <span>{{ index + 1 }}.{{ item.topic }}</span>
              </div>
              <div>
                <el-checkbox-group v-model="item.user_answer">
                  <el-checkbox
                    :label="idx"
                    v-for="(e, idx) in item.option"
                    :key="idx"
                    >{{ e }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{
          $t("Events.8f0@cancel")
        }}</el-button>
        <el-button
          :disabled="isSubmit"
          size="small"
          type="primary"
          @click="questionnaireSubmit"
          >{{ $t("Events.de9@confirm") }}</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      dialogVisible: false,
      personalQuestionList: [],
      dialogTitle: '',
      questionId: 0,
      isSubmit: false
    }
  },
  props: {
    type: {
      type: Number
    }
  },
  computed: {
    ...mapState({
      questionnaire: state => state.Process.questionnaire,
      isRegister: state => state.Process.headerData.is_register
    }),
    computedType () {
      let type = this.$route.query.type
      if (type == 1) return 'Project'
      if (type == 2) return 'Event'
      if (type == 3) return 'Course'
      if (type == 4) return 'Meeting'
    }
  },
  components: {},

  created () {
  },

  methods: {
    // 查看试卷详情
    async getQestionList (det) {
      let { id } = det
      if (this.isRegister) {
        // 注册了
        this.questionId = id
        let type = this.type
        let ajaxType = {
          1: 'questionnaireContent',
          2: 'eventQuestionnaireContent',
          3: 'courseQuestionnaireContent',
          4: 'meetingQuestionnaireContent'
        }
        let res = await this.$http[ajaxType[type]]({
          questionnaire_id: id
        })
        if (res.status == 200) {
          this.dialogVisible = true
          res.data.all_topic.forEach(item => {
            if (item.type == 2) {
              this.$set(item, 'user_answer', [])
            } else {
              this.$set(item, 'user_answer', '')
            }
          })
          this.personalQuestionList = res.data.all_topic
          this.dialogTitle = res.data.questionnaire_title
        }
      } else {
        // alert('請注冊')
        this.$message.warning('Please register first!')
      }
    },

    async questionnaireSubmit () {
      // 将用户的回答从数字装为字符串
      this.personalQuestionList.forEach(item => {
        if (item.type == 2) {
          item.user_answer = item.user_answer.join(',')
        }
      })
      // 提交
      let type = this.type
      let ajaxType = {
        1: 'questionnaireSubmit',
        2: 'eventQuestionnaireSubmit',
        3: 'courseQuestionnaireSubmit',
        4: 'meetingQuestionnaireSubmit'
      }
      let res = await this.$http[ajaxType[type]]({
        questionnaire_id: this.questionId,
        all_topic: JSON.stringify(this.personalQuestionList)
      })
      if (res.status == 200) {
        this.$bus.$emit(`update${this.computedType}Detail`)
        this.dialogVisible = false
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off(`update${this.computedType}Detail`)
  }
}

</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  ::v-deep .el-dialog{
    width: 30%;
  }
  .question-list {
    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #f1f1f1;
    }
  }
  
  .simulate {
    padding-left: 20px;
  
    ::v-deep .el-radio,
    .el-checkbox {
      cursor: pointer;
      display: flex;
      margin-bottom: 30px;
  
      .el-radio__inner,
      .el-radio__input,
      .el-checkbox__input,
      .el-checkbox__inner {
        position: relative;
        display: block;
      }
    }
  }
  
  .submit {
    border: none;
    color: #999999;
    background: #f1f1f1;
  }
}
@media (max-width: 576px) {
  ::v-deep .el-dialog{
    width: 80%;
  }
  .question-list {
    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #f1f1f1;
    }
    .el-button{
      padding: 7px 10px;
      width: 60px;
      height: 30px;
      font-size: 12px;
    }
  }
  .question-content{
    font-size: 12px;
  }
  .simulate {
    padding-left: 10px;
  
    ::v-deep .el-radio,
    .el-checkbox {
      cursor: pointer;
      display: flex;
      margin-bottom: 15px;
  
      .el-radio__inner,
      .el-radio__input,
      .el-checkbox__input,
      .el-checkbox__inner {
        position: relative;
        display: block;
      }
    }
  }
  
  .submit {
    border: none;
    color: #999999;
    background: #f1f1f1;
  }
}
</style>
